import { ref } from "vue";

let windowScroll = ref(0);

let isSSR = utilities.isSSR();

// dummy for server - server doesnt know what a window even is!
let windowScrollCompositionServer = () => {
  return {
    windowScroll,
  };
};
let debounceTimeout;
let debounceDelay = 10;
let windowScrollCompositionClient = () => {
  let setWindowScroll = () => {
    windowScroll.value = window.scrollY;
  };

  // populate size right now
  setWindowScroll();

  // listen to window resize
  document.addEventListener(
    "scroll",
    () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = utilities.setClientTimeout(setWindowScroll, debounceDelay);
    },
    { passive: true }
  );

  return {
    windowScroll,
  };
};

export default isSSR ? windowScrollCompositionServer : windowScrollCompositionClient;
